import {
  Box,
  ColorPreset,
  JustifyContent,
  Layer,
  ResponsiveValue,
  useTheme,
  Visibility,
  ZIndex,
} from "@gocardless/flux-react";

import { NAV_WIDTH, NAV_WIDTH_XL } from "./constants";
import { layoutStyle, headerStyle, contentStyle } from "./styles";

const MAX_WIDTH_LG = "824px";
const MAX_WIDTH_MD = "728px";
const MAX_WIDTH_SM = "712px";
const MAX_WIDTH_XS = "100%";

export interface SingleFixedLayoutWithNavProps {
  header?: React.ReactNode;
  children?: React.ReactNode;
  navigation?: React.ReactNode;
  spaceAbove?: ResponsiveValue<number | string>;
}

export const SingleFixedLayoutWithNav: React.FC<
  SingleFixedLayoutWithNavProps
> = ({ spaceAbove, navigation, header, children }) => {
  const { theme } = useTheme();

  return (
    <Box
      css={{
        position: "relative",
      }}
      minHeight={`calc(100dvh - ${spaceAbove || 0}px)`}
      layout="flex"
      justifyContent={JustifyContent.FlexStart}
      bg={ColorPreset.BackgroundLight_02}
    >
      <Visibility visible={["none", null, null, "inline-block"]}>
        <Box
          css={{
            position: "fixed",
          }}
          height={`calc(100% - ${spaceAbove || 0}px)`}
          width={[NAV_WIDTH, null, null, null, NAV_WIDTH_XL]}
        >
          {navigation}
        </Box>
      </Visibility>

      <Box css={layoutStyle(theme)}>
        {header && (
          <Layer mode="relative" zIndex={350 as ZIndex}>
            <Box bg={ColorPreset.BackgroundLight_02} css={headerStyle(theme)}>
              {header}
            </Box>
          </Layer>
        )}

        <Box
          maxWidth={[
            MAX_WIDTH_XS,
            MAX_WIDTH_SM,
            MAX_WIDTH_MD,
            MAX_WIDTH_LG,
            null,
          ]}
          gutterH={[1.5, 1.5, 2, 4, 4]}
          css={contentStyle(theme, { hasHeader: !!header })}
          layout="flex"
          justifyContent={JustifyContent.Center}
        >
          {children}
        </Box>
      </Box>
    </Box>
  );
};
